<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-09-28 14:43:30
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-09-28 14:59:28
 * @FilePath: \yda_web_manage\src\components\Toast\index.vue
 * @Description: toast组件
-->
<template>
  <div class="toast" v-if="isShow">{{ content }}</div>
</template>

<script>
import { defineComponent, onMounted, ref, watchEffect } from 'vue'
export default defineComponent({
  components: {},
  props: {
    content: String,
    isShow: Boolean
  },
  setup(props, { emit }) {
    watchEffect(() => {
      if (props.isShow) {
        setTimeout(() => {
          emit('update:isShow', false)
        }, 1000)
      }
    })
    onMounted(() => {})
    return {}
  }
})
</script>
<style lang="less" scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: fit-content;
  height: 40px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 33px;
  padding: 10px 26px;
}
</style>
