<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-09-26 14:42:45
 * @LastEditors: cyx 
 * @LastEditTime: 2024-01-26 11:31:23
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-01-17 16:14:20
 * @FilePath: \yda_web_manage\src\views\equipmentManage\detail.vue
 * @Description: 终端详情
-->
<template>
  <div class="equipment-detail">
    <!-- 基础信息 -->
    <div :class="['basic-information', { 'jindun-min-height': allInfo.equipmentType === '金盾' }]">
      <p class="title">基础信息</p>
      <div class="info" v-if="deviceInfo && Object.keys(deviceInfo).length">
        <!-- 终端图片 -->
        <div class="equipment-img">
          <img :src="deviceInfo.deviceImg" alt="" />
        </div>
        <div class="info-content">
          <div class="line1">
            <div class="info-item" v-for="(item, index) in deviceInfo.infoList" :key="index">
              <template v-if="index !== deviceInfo.infoList.length - 1">
                <div class="info-desc">
                  <div
                    :class="['status-dot', allInfo[Object.keys(item)[1]] ? 'green' : 'red']"
                    v-if="item.title === '终端状态'"
                  ></div>
                  <!-- 所属组织省略悬停显示完整 -->
                  <a-tooltip placement="top" v-if="item.ellipsis && allInfo[Object.keys(item)[1]].length > 13">
                    <template #title>
                      <span>{{ allInfo[Object.keys(item)[1]] }}</span>
                    </template>
                    <div class="default-text-style  text-ellipsis">
                      <span>{{ allInfo[Object.keys(item)[1]] }}</span>
                    </div>
                  </a-tooltip>
                  <div v-else class="default-text-style">
                    <span v-if="Object.keys(item)[1] == 'deviceState'">{{
                      allInfo[Object.keys(item)[1]] ? '在线' : '离线'
                    }}</span>
                    <span v-else>{{ allInfo[Object.keys(item)[1]] ?? '--' }}</span>
                  </div>
                </div>
                <p class="info-title">{{ item.title }}</p>
              </template>
            </div>
          </div>
          <div class="line2">
            <span class="info-desc default-text-style">
              {{ allInfo[Object.keys(deviceInfo.infoList[deviceInfo.infoList.length - 1])[1]] ?? '--' }}
            </span>
            <p class="info-title">
              {{ deviceInfo.infoList[deviceInfo.infoList.length - 1].title }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 配置项 -->
    <div class="configuration-item" v-if="allInfo.equipmentType !== '金盾'">
      <p class="title">配置项</p>
      <div class="configuration-content">
        <!-- 天玺 -->
        <div class="column-content" v-if="allInfo.equipmentType === '天玺'">
          <!-- 高拍仪模式 -->
          <div class="list-item" v-if="allInfo.bindPhotograph">
            <span class="text-desc">高拍仪模式</span>
            <a-switch v-model:checked="checked" @change="checkStatus($event, 1)" />
          </div>
          <!-- 待机时长设置 -->
          <div class="list-item" v-btn="'PC_EQUIPMENT_MANAGE'">
            <span class="text-desc">待机时长设置</span>
            <a-select
              v-model:value="tianXiInfo.timeSetting.value1"
              style="width: 104px;margin-right: 8px"
              @change="timeChange($event, 1)"
            >
              <a-select-option
                :value="item.value"
                v-for="(item, index) in tianXiInfo.timeSetting['options1']"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
            <!-- TODO:选择永不关机，分钟隐藏判断条件 -->
            <span class="text-desc" v-if="tianXiInfo.timeSetting.value1 !== -1">分钟</span>
          </div>
          <!-- 休眠时长设置 -->
          <div class="list-item">
            <span class="text-desc">休眠时长设置</span>
            <a-select
              v-model:value="tianXiInfo.timeSetting.value2"
              style="width: 104px;margin-right: 8px"
              @change="timeChange($event, 2)"
            >
              <a-select-option
                :value="item.value"
                v-for="(item, index) in tianXiInfo.timeSetting['options2']"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
            <!-- TODO:选择永不休眠，分钟隐藏判断条件 -->
            <span class="text-desc" v-if="tianXiInfo.timeSetting.value2 !== -1">分钟</span>
          </div>
          <!-- 语音播报 -->
          <div class="list-item">
            <span class="text-desc">语音播报</span>
            <a-switch v-model:checked="tianXiInfo.voiceBroadcast" @change="checkStatus($event, 2)" />
          </div>
          <!-- 特权连续用印模式 -->
          <div class="list-item" v-if="allInfo.equipmentModel === 'YDA-APlus'">
            <span class="text-desc">特权连续用印模式</span>
            <a-switch v-model:checked="tianXiInfo.privilegeSeal" @change="checkStatus($event, 3)" />
          </div>
          <div class="list-item" v-if="allInfo.equipmentModel === 'YDA-APlus'">
            <span class="text-desc">灵敏度设置</span>
            <a-select
              style="width: 104px; margin-right: 8px"
              v-model:value="timeSetting.qyroValue"
              @change="QyroChange"
            >
              <a-select-option :value="item.value" v-for="(item, index) in timeSetting.optionsQyro" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-tooltip placement="rightBottom">
              <template #title>
                <span>此功能用于检测印章是否被移动，数值越小灵敏度越高</span>
              </template>
              <img :src="mark" class="mark" />
            </a-tooltip>
          </div>
          <div v-if="allInfo.remoteWake !== -1">
            <span class="text-desc">远程唤醒</span>
            <a-switch v-model:checked="remoteWakeupCheck" @change="remoteWakeup" />
          </div>
        </div>
        <!-- 高拍仪 -->
        <div class="row-content" v-if="allInfo.equipmentType === '天眼'">
          <div class="module1">
            <span class="text-desc">未识别到终端</span>
            <a-input
              v-model:value="tianYanInfo.timeInput"
              class="time-input"
              @blur="timeChange($event, 3)"
              @change="changeTime"
            />
            <span class="text-desc">s后自动关仓<span class="tip">（可输入范围为1-60s）</span></span>
          </div>
          <div>
            <a-checkbox v-model:checked="checked" @change="checkStatus($event, 4)">用印环境拍摄</a-checkbox>
            <a-tooltip placement="rightBottom">
              <template #title>
                <span>开启时，在高拍仪用印前需拍摄环境信息</span>
              </template>
              <img :src="mark" alt="" class="mark" />
            </a-tooltip>
          </div>
        </div>
      </div>
      <!-- toast -->
      <Toast :content="tip" v-model:isShow="showTip"></Toast>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import tianxi from '@/assets/images/equipmentManage/tianxi.png'
import tianyan from '@/assets/images/equipmentManage/gaopaiyi.png'
import jindun from '@/assets/images/equipmentManage/jindun.png'
import mark from '@/assets/images/equipmentManage/mark.png'
import Toast from '@/components/Toast'
import { useRoute } from 'vue-router'
import {
  getDeviceDetail,
  setAwaitTime,
  setPhotographType,
  settingRemoteWake,
  setGateTime,
  setDeviceFace,
  setDormancyTime,
  settingQyroParameter,
  setContinuousPrinting,
  setVoiceBroadcast
} from '@/apis/deviceManage'
import { cmsNotice } from '@/utils/utils'

export default defineComponent({
  components: {
    Toast,
  },
  props: {},
  setup() {
    const timeSetting = reactive({
      value1: 5,
      value2: 3,
      qyroValue: 1,
      options1: [
        {
          name: '永不关机',
          value: -1,
        },
        {
          name: '5',
          value: 5,
        },
        {
          name: '8',
          value: 8,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '20',
          value: 20,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '60',
          value: 60,
        },
        {
          name: '90',
          value: 90,
        },
        {
          name: '120',
          value: 120,
        },
      ],
      options2: [
        {
          name: '永不休眠',
          value: -1,
        },
        {
          name: '3',
          value: 3,
        },
        {
          name: '5',
          value: 5,
        },
        {
          name: '8',
          value: 8,
        },
        {
          name: '10',
          value: 10,
        },
      ],
      optionsQyro: [],
    })
    const equipmentId = ref('')
    const route = useRoute()
    const tip = ref('')
    const showTip = ref(false)
    const checked = ref(false)
    const remoteWakeupCheck = ref(false)
    const tianXiInfo = ref({
      timeSetting: {
        value1: 5,
        value2: 3,
        options1: [
          {
            name: '永不关机',
            value: -1
          },
          {
            name: '5',
            value: 5
          },
          {
            name: '8',
            value: 8
          },
          {
            name: '10',
            value: 10
          },
          {
            name: '15',
            value: 15
          },
          {
            name: '20',
            value: 20
          },
          {
            name: '30',
            value: 30
          },
          {
            name: '60',
            value: 60
          },
          {
            name: '90',
            value: 90
          },
          {
            name: '120',
            value: 120
          }
        ],
        options2: [
          {
            name: '永不休眠',
            value: -1
          },
          {
            name: '3',
            value: 3
          },
          {
            name: '5',
            value: 5
          },
          {
            name: '8',
            value: 8
          },
          {
            name: '10',
            value: 10
          }
        ]
      },
      infoList: [
        {
          title: '终端编号',
          number: '',
          ellipsis: false
        },
        {
          title: '终端类型',
          equipmentType: '',
          ellipsis: false
        },
        {
          title: '终端型号',
          equipmentModel: '',
          ellipsis: false
        },
        {
          title: '终端状态',
          deviceState: '',
          ellipsis: false
        },
        {
          title: '所属组织',
          organization: '',
          ellipsis: true
        },
        {
          title: '版本号',
          firmwareEdition: '',
          ellipsis: false
        },
        {
          title: '绑定印章',
          bindSeal: '',
          ellipsis: false
        }
      ],
      deviceImg: tianxi,
      voiceBroadcast: false,
      privilegeSeal: false
    })
    const tianYanInfo = ref({
      timeInput: 5,
      infoList: [
        {
          title: '终端编号',
          number: '',
          ellipsis: false
        },
        {
          title: '终端类型',
          equipmentType: '',
          ellipsis: false
        },
        {
          title: '终端型号',
          equipmentModel: '',
          ellipsis: false
        },
        {
          title: '所属组织',
          organization: '',
          ellipsis: true
        },
        {
          title: '版本号',
          firmwareEdition: '',
          ellipsis: false
        },
        {
          title: '终端位置',
          address: '',
          ellipsis: false
        }
      ],
      deviceImg: tianyan
    })
    const jinDunInfo = ref({
      infoList: [
        {
          title: '终端编号',
          number: '',
          ellipsis: false
        },
        {
          title: '终端类型',
          equipmentType: '',
          ellipsis: false
        },
        {
          title: '终端型号',
          equipmentModel: '',
          ellipsis: false
        },
        {
          title: '所属组织',
          organization: '',
          ellipsis: true
        },
        {
          title: '版本号',
          firmwareEdition: '',
          ellipsis: false
        },
        {
          title: '绑定印章',
          bindSeal: '',
          ellipsis: false
        }
      ],
      deviceImg: jindun
    })
    const deviceInfo = ref({})
    const allInfo = ref([])
    const timeChange = (e, type) => {
      switch (type) {
        case 1:
          //天玺-待机时长设置
          setAwaitTime(equipmentId.value, e)
            .then((res) => {
              if (res.success) cmsNotice('success', '设置成功')
            })
            .catch((err) => {
              console.log(err)
            })
          break
        case 2:
          //天玺-休眠时长设置
          setDormancyTime(equipmentId.value, e)
            .then((res) => {
              if (res.success) cmsNotice('success', '设置成功')
            })
            .catch((err) => {
              console.log(err)
            })
          break
        case 3:
          //高拍仪
          if (+e.target.value < 1 || +e.target.value > 60) {
            showTip.value = true
            tianYanInfo.value.timeInput = 5
            tip.value = '自动关仓可输入范围为1-60s'
            return
          }
          setGateTime(equipmentId.value, e.target.value)
            .then((res) => {
              if (res.success) {
                if (res.success) cmsNotice('success', '设置成功')
              }
            })
            .catch((err) => {
              console.log(err)
            })
          break
      }
    }
    //获取终端基础信息
    const getDeviceInfo = () => {
      console.log('获取终端基础信息', route.query)
      getDeviceDetail({ equipmentId: equipmentId.value })
        .then((res) => {
          if (res.success) {
            console.log('终端详情', res.data)
            allInfo.value = res.data

            // TODO :金盾展示详情
            switch (res.data.equipmentType) {
              case '金盾':
                deviceInfo.value = jinDunInfo.value
                break
              case '天玺':
                deviceInfo.value = tianXiInfo.value
                tianXiInfo.value.timeSetting.value1 = allInfo.value.awaitTime //待机时长
                tianXiInfo.value.timeSetting.value2 = allInfo.value.sleepTime //休眠时长
                checked.value = allInfo.value.photographType === 1 //高拍仪模式
                tianXiInfo.value.voiceBroadcast = allInfo.value.voice === 1 //语音播报
                tianXiInfo.value.privilegeSeal = allInfo.value.sealPattern === 2 //特权连续用印模式1关2开
                timeSetting.qyroValue = allInfo.value.gyroParameter??1
                remoteWakeupCheck.value = allInfo.value.remoteWake==2
                break
              case '天眼':
                deviceInfo.value = tianYanInfo.value
                tianYanInfo.value.timeInput = allInfo.value.gateTime
                checked.value = allInfo.value.deviceFace === 1
                break
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // 远程唤醒
    const remoteWakeup = (e) => {
      console.log('远程唤醒', e)
      settingRemoteWake(equipmentId.value, e ? 2 : 1)
        .then((res) => {
          if (res.success) {
            remoteWakeupCheck.value = e
            cmsNotice('success', '设置成功')
          } else {
            remoteWakeupCheck.value = !remoteWakeupCheck.value
          }
        })
        .catch((err) => {
          console.log(err)
          remoteWakeupCheck.value = !remoteWakeupCheck.value
        })
    }
    
    const checkStatus = (e, type) => {
      console.log('事件e的值', e)
      switch (type) {
        case 1:
          //高拍仪模式
          setPhotographType(equipmentId.value, e ? 1 : 2)
            .then(res => {
              if (res.success) {
                checked.value = e
                cmsNotice('success', '设置成功')
              } else {
                checked.value = !checked.value
              }
            })
            .catch(err => {
              console.log(err)
              checked.value = !checked.value
            })
          break
        case 2:
          //TODO:语音播报
          setVoiceBroadcast({ equipmentId: equipmentId.value, buttonSwitch: e ? 1 : 2 })
            .then(res => {
              console.log('语音播报结果', res)
              if (res.success) {
                cmsNotice('success', '设置成功')
              } else {
                tianXiInfo.value.voiceBroadcast = !e
              }
            })
            .catch(err => {
              console.log(err)
            })
          break
        case 3:
          //TODO:特权连续用印模式
          setContinuousPrinting({ equipmentId: equipmentId.value, sealPattern: e ? 2 : 1 })
            .then(res => {
              console.log('特权连续用印模式', res)
              if (res.success) {
                cmsNotice('success', '设置成功')
              } else {
                tianXiInfo.value.privilegeSeal = !e
              }
            })
            .catch(err => {
              console.log(err)
            })
          break
        case 4:
          //用印环境拍摄
          setDeviceFace(equipmentId.value, e.target.checked ? 1 : 2)
            .then(res => {
              if (res.success) {
                checked.value = e.target.checked
                cmsNotice('success', '设置成功')
              } else {
                checked.value = !checked.value
              }
            })
            .catch(err => {
              console.log(err)
              checked.value = !checked.value
            })
          break
      }
    }

    const changeTime = e => {
      tianYanInfo.value.timeInput = e.target.value.replace(/\D/g, '')
    }
    // #灵敏度下拉选项
    const initQyroOptions = () => {
      let list = []
      for (let i = 0; i < 20; i++) {
        list.push({ name: i + 1, value: i + 1 })
      }
      timeSetting.optionsQyro = list
      console.log('ddd' + timeSetting.optionsQyro)
    }
    initQyroOptions()
    // #灵敏度设置
    const QyroChange = async () => {
      console.log('灵敏度值' + timeSetting.qyroValue)
      try {
        const resulut = await settingQyroParameter(equipmentId.value, timeSetting.qyroValue)
        resulut.success ? cmsNotice('success', '设置成功') : cmsNotice('warn', '设置失败')
      } catch (e) {
        cmsNotice('warn', '设置失败' + e)
      }
    }
    onMounted(() => {
      equipmentId.value = route.query.equipmentId
      getDeviceInfo()
    })

    return {
      deviceInfo,
      tianxi,
      tianyan,
      mark,
      checked,
      timeChange,
      tip,
      showTip,
      getDeviceInfo,
      checkStatus,
      allInfo,
      changeTime,
      equipmentId,
      timeSetting,
      QyroChange,
      remoteWakeupCheck,
      remoteWakeup,
      jinDunInfo,
      tianYanInfo,
      tianXiInfo
    }
  },
})
</script>
<style lang="less" scoped>
.equipment-detail {
  padding: 0 24px;
  .basic-information {
    min-width: 1184px;
    background: #fff;
    padding: 24px;
    border-radius: 2px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      margin-bottom: 16px;
    }
    .info {
      min-width: 1136px;
      padding: 24px 24px 24px 32px;
      background: #fafafa;
      display: flex;
      flex-direction: row;
      align-items: center;
      .equipment-img {
        min-width: 100px;
        min-height: 100px;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #e9e9e9;
        margin-right: 32px;
        position: relative;
        img {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .info-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .line1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          .info-item {
            margin-right: 70px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .line2 {
          margin-top: 16px;
        }
        .info-desc {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          margin-bottom: 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .status-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .green {
            background: #52c41a;
          }
          .red {
            background: #f5212d;
          }
          .text-ellipsis {
            max-width: 13em;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .default-text-style {
            white-space: nowrap;
          }
        }
        .info-title {
          width: 56px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 20px;
        }
      }
    }
  }
  .jindun-min-height {
    min-height: 661px;
  }
  .configuration-item {
    min-width: 1184px;
    padding: 24px;
    min-height: 313px;
    background: #fff;
    margin-top: 24px;
    border-radius: 2px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      margin-bottom: 29px;
    }
    .configuration-content {
      .column-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .list-item {
          margin-bottom: 16px;
        }
      }
      .row-content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .module1 {
        margin-right: 72px;
      }
      .text-desc {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-right: 8px;
        .tip {
          font-size: 14px;
          font-weight: 400;
          color: rgba(153, 153, 153, 0.85);
          line-height: 20px;
        }
      }
      .time-input {
        width: 72px;
        margin-right: 8px;
      }
      .mark {
        width: 12px;
        height: 12px;
      }
    }
  }
  .qyro-text {
    padding-left: 1em;
  }
}
</style>
